<template>
    <div class="py-10">
        <div class="uppercase font-bold mb-4">{{header}}</div>

        <div class="grid grid-cols-2 lg:grid-cols-4 gap-5">
            <div v-for="sermon in sermons.slice(0, 4)">
                <RouterLink :to="`/sermons/${sermon.id}`" class="h-40 bg-red-200 section-image bg-slate-200">
                    <img :src="sermon.data.cover_image" class="w-full" :alt="sermon.data.title + ' image'" loading="lazy">
                    <div class="hidden md:block">
                        <div class="text-lg font-bold">{{ sermon.data.title }}</div>
                        <div class="text-sm text-[#f97316]"><span class="font-bold">Upload date:</span> {{ new
                            Date(sermon.data.date_created).toDateString() }}</div>
                    </div>
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useDataStore } from '../../stores/useData';

let dataStore  = useDataStore()

let { sermons } = storeToRefs(dataStore)

defineProps(['header'])
</script>

<style scoped>
.section-image{
    background-image: url('../../assets/images/placeholder.jpg');
    background-size: contain;
    background-position: center;
}
</style>