<template>
    <div>
        <Hero/>

        <div v-if="!searchQuery">
            <div class="px-4 md:px-20 text-xl flex flex-col gap-y-4 mt-5">
                <p>
                    <i>“And now, brethren, I commend you to God, and to the word of his grace, which is able to build you up, and to give you an inheritance among all them which are sanctified.”</i>
                </p>
                <p>
                    Our sermons are thorough, Bible-based teachings to build you up in the faith and provoke you unto love and good works. 
                </p>
                <p>Download and listen to our latest sermons.</p>
            </div>

            <div v-if="initialDataLoad">
                <Uploads header="Recent uploads" class="p-4 md:p-20"/>
                <Messages class="p-4 md:px-20"/>
            </div>
            <div v-else>
                <Loader />
            </div>
        </div>

        <div v-else>
            <Searched :searchQuery="searchQuery" :sermons="searchedSermons" class="p-4 md:px-20" />
        </div>
    </div>
</template>

<script setup>
import Hero from '../components/Sermons/Hero.vue';
import Uploads from '../components/Sermons/Uploads.vue';
import Messages from '../components/Sermons/Messages.vue'
import Loader from '../components/Loader.vue';
import { useDataStore } from '../stores/useData';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import Searched from '../components/Sermons/Searched.vue'
import { ref, watch } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router'

let dataStore = useDataStore()

let { initialDataLoad, sermons } = storeToRefs(dataStore)

let route = useRoute()
let searchQuery = ref(null)

if (route.query.q) {
    searchQuery.value = (route.query.q).toLowerCase()    
}

let temp

let searchedSermons = ref(null)

onBeforeRouteUpdate(async (to, from) => {
    if (to.query.q) {
        searchQuery.value = (to.query.q).toLowerCase()
        searchedSermons.value = filterSermons(searchQuery.value)
    } else {
        searchQuery.value = null
    }
});

if (initialDataLoad.value) {
    temp = [...sermons.value]
    searchedSermons.value = filterSermons(searchQuery.value)
}
watch(sermons, (newSermons) => {
    temp = [...newSermons]
    searchedSermons.value = filterSermons(searchQuery.value)
})

function filterSermons(q) {
    let arr = []
    if (searchQuery) {
        temp.filter(sermon => {
            if(sermon.data.title.toLowerCase().includes(q)) {
                arr.push(sermon)
            }
        })
    }

    return arr
}

</script>

<style lang="scss" scoped>

</style>